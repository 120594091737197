<template>
  <Breadcrumb :home="breadcrumb.home" :model="breadcrumb.items" style="padding-top:0px;background: none;border: none;"/>
  <div class="card">
    <div>
      <Toolbar class="p-mb-4" style="background-color: #fff;border:none;padding-bottom:0px;">
        <template #left>
          <Button v-permission="['1010','1313','tjcd']" @click="addOrUpdate()"
                  style="background: #12A25D;border-color: #12A25D" label="添加菜单" icon="pi pi-plus" class="p-mr-2"/>
        </template>

        <template #right>
          <div class="p-inputgroup">
            <InputText style="width:200px;" v-model="name" placeholder="请输入菜单名称"/>
            <Button icon="pi pi-search" @click="getList" @keydown.enter="getList"
                    style="background: #FFFFFF;color: #000000;opacity: 0.25;"/>
          </div>
        </template>
      </Toolbar>
      <TreeTable :value="list" :row-hover="true">
        <template #empty>
          没有发现数据。
        </template>
        <Column field="name" header="菜单名称" :expander="true" style="width:15em"></Column>
        <Column field="type" header="类型">
          <template #body="data">
            <span style="padding:0.1rem 0.3rem"
                  :class="data.node.data.type == 0 ? 'menu-status':(data.node.data.type ==1?'mulu-status':'danger-status')">
              <label>{{ data.node.data.type == 0 ? '菜单' : (data.node.data.type == 1 ? '目录' : '按钮') }}</label></span>
          </template>
        </Column>
        <Column field="url" header="url地址"></Column>
        <Column field="icon" header="字体图标名称">
          <template #body="data">
            <i :class="data.node.data.icon"></i>
          </template>
        </Column>
        <Column field="idx" header="排序索引"></Column>
        <Column field="enable" header="启用">
          <template #body="data">
            <span :class="data.node.data.enable == 0 ? 'admin-status' : 'danger-status'">
              <label style="width: 48%;display: inline-block;text-align: center;line-height: 22px">{{
                  data.node.data.enable == 0 ? '启用' : '禁用'
                }}</label>
            </span>
          </template>
        </Column>
        <Column frozen alignFrozen="right" :exportable="false" header="操作" style="min-width:15rem;">
          <template #body="data">
            <a v-permission="['1010','1313','cdbj']"
               style="padding-right:8px; margin:0;border-right:1px solid rgba(216, 216, 216, 1);" href="javascript:"
               class="a-button s-button" @click="addOrUpdate(data.node.data)">编辑</a>
            <a v-permission="['1010','1313','cdsc']" style="padding:0 8px;" href="javascript:" class="a-button s-button"
               @click="deleteItem(data.node.data)">删除</a>
          </template>
        </Column>
        <template #paginatorLeft></template>
      </TreeTable>
      <Paginator :rows="page.size" :totalRecords="page.total" @page="changePage"
                 template=" PrevPageLink PageLinks NextPageLink  RowsPerPageDropdown CurrentPageReport"
                 :rowsPerPageOptions="[10,20,50,100]"
                 currentPageReportTemplate="共{totalRecords} 条记录">
        <template #right>
          <label>跳至&nbsp;&nbsp;</label>
          <InputNumber v-model="page.current" @keydown.enter="getList" v-tooltip.focus="'按下回车键后跳转'"
                       inputStyle="width: 40px;"/>
          <label>&nbsp;&nbsp;页</label>
        </template>
      </Paginator>
      <add-or-update-menus ref="addOrUpdateRole" v-if="addOrUpdateDisplay"
                           v-on:close="closeDialog"></add-or-update-menus>
    </div>
  </div>
</template>

<script>
import AddOrUpdateMenus from "./addOrUpdateMenus";
import TreeTable from "primevue/treetable";
import Column from "primevue/column";

export default {
  name: "menu",
  components: { AddOrUpdateMenus, TreeTable, Column },
  data() {
    return {
      list: null,
      page: {
        size: 10,
        total: "",
        current: 1,
      },
      addOrUpdateDisplay: false,
      breadcrumb: {
        home: { label: "系统管理", to: "#" },
        items: [{ label: "菜单管理", to: "#" }],
      },
      name: null,

    };
  },
  created() {
    this.getList();
  },
  mounted() {
  },
  methods: {
    getList() {
      let me = this;
      this.$http(
        "/menu/getList",
        "get",
        {
          current: this.page.current,
          size: this.page.size,
          name: this.name,
          enable: null,
        },
        function(res) {
          console.log(res);
          me.page.total = res.data.total;
          me.list = res.data.records;
          console.log(me.list);
        }
      );
    },
    addOrUpdate(data) {
      this.addOrUpdateDisplay = true;
      this.$nextTick(() => {
        this.$refs.addOrUpdateRole.init(data);
      });
    },
    closeDialog() {
      this.addOrUpdateDisplay = false;
      this.getList();
    },
    changePage(page) {
      this.page.size = page.rows;
      this.page.current = page.page + 1;
      this.getList();
    },

    deleteItem(data) {
      this.$confirm.require({
        message: '您确定要删除"' + data.name + '"吗?',
        header: "删除本条数据",
        icon: "pi pi-info-circle",
        acceptLabel: "确定",
        accept: () => {
          // console.log(data)
          this.delete(data);
        },
        rejectLabel: "取消",
        reject: () => {
        },
      });
    },
    delete(data) {
      console.log(data.id);
      let m = this;
      this.$http("/menu/del/" + data.id, "delete", null, function(res) {
        console.log(res);
        m.getList();
      });
    },
  },
};
</script>

<style scoped>
p {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding: 0 1rem;
  cursor: pointer;
}

h3 {
  padding: 1rem 0 1rem 10px;
  line-height: 1;
  font-weight: 800;
  position: relative;
}

h3::after {
  content: "";
  width: 5px;
  height: 16px;
  background-color: var(--primary-color);
  position: absolute;
  left: 0px;
}

::v-deep(.p-datatable .p-datatable-thead > tr > th) {
  min-width: 100px;
  white-space: nowrap;
}

.menu-status {
  width: 56px;
  height: 22px;
  border: 1px double #91d5ff;
  color: #1890ff;
  font-size: 12px;
  background-color: #e6f7ff;
}

.mulu-status {
  width: 56px;
  height: 22px;
  border: 1px double yellow;
  color: gold;
  font-size: 12px;
  background-color: lightyellow;
}

.admin-status {
  width: 56px;
  height: 22px;
  border: 1px double #91d5ff;
  color: #1890ff;
  font-size: 12px;
  background-color: #e6f7ff;
}

.user-status {
  width: 56px;
  height: 22px;
  border: 1px double #b7eb8f;
  color: #52c41a;
  font-size: 12px;
  background-color: #f6ffed;
}

.danger-status {
  width: 56px;
  height: 22px;
  border: 1px double #f47983;
  color: #f00056;
  font-size: 12px;
  background-color: #ffb3a7;
}

.a-button {
  color: #12a25d;
}
</style>
